#table-container {
    /* max-height: 300px;  */
    overflow-y: auto; /* Enable vertical scrolling */
  }
  
  #table-container::-webkit-scrollbar {
    width: 7px; /* Width of the scrollbar */
  }
  
  #table-container::-webkit-scrollbar-track {
    background-color: transparent; /* Color of the scrollbar track */
  }
  
  #table-container::-webkit-scrollbar-thumb {
    background-color: #b4a3e8; /* Color of the scrollbar thumb */
    border-radius: 5px; /* Rounded corners of the scrollbar thumb */
  }
  
  #table-container::-webkit-scrollbar-thumb:hover {
    background-color: #2B73FF; /* Color of the scrollbar thumb on hover */
  }

  .spContent43 {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  
  .spContent43 button {
    margin-right: 10px;
    font-size: 12px; /* Adjust the font size as needed */
    white-space: nowrap; /* Ensures that text doesn't wrap onto a new line */
  }
  


  #handler-container {
    /* max-height: 300px;  */
    overflow-x: auto; /* Enable vertical scrolling */
    overflow-y: hidden;
  }
  
  #handler-container::-webkit-scrollbar {
    width: 3px; /* Width of the scrollbar */
    height: 5px;
  }
  
  #handler-container::-webkit-scrollbar-track {
    background-color: transparent; /* Color of the scrollbar track */
  }
  
  #handler-container::-webkit-scrollbar-thumb {
    background-color: #b4a3e8; /* Color of the scrollbar thumb */
    border-radius: 5px; /* Rounded corners of the scrollbar thumb */
  }
  
  #handler-container::-webkit-scrollbar-thumb:hover {
    background-color: #2B73FF; /* Color of the scrollbar thumb on hover */
  }

