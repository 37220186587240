.loginHeading1{
    font-size: 21px;
    font-weight: 400;
    
}

.span1{
    color: #2B73FF;
}

.loginHeading2{
    font-size: 55px;
    font-weight: 500;
    line-height: 67px;
    font-style: normal;
    

}

.loginHeading3{
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 18px;
    color: black;

}

.divButton1{
    float: right;

}

.loginButton1{
    background: #2B73FF;
    color: white;
    width: 110%;
}

.divLogin1{
    position: relative;
    height: 100vh;
    
}

.card1{
    position: absolute;
    top: 10%;
    right: 10%;
    background-color:white;

}


