/* .nav-area {
    display: flex;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
    padding: 10px 20px;
  } */
  
  /* .logo {
    text-decoration: none;
    font-size: 25px;
    color: inherit;
    margin-right: 20px;
  } */
  
  .menus26 {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    list-style: none;
    gap: 25px;
  }
  
  .menu-items26 {
    position: relative;
    font-size: 16px;
    color: black;
    font-weight: 600;
    text-decoration: none;
  }
  
  .menu-items26 a {
    display: block;
    font-size: 16px;
    color: black;
    text-decoration: none;
    font-weight: 600;

  }
  
  .menu-items26 button {
    display: flex;
    align-items: center;
    color: inherit;
    font-size: inherit;
    font-weight: 600;
    border: none;
    background-color: transparent;
    cursor: pointer;
    width: 100%;
  }
  
  button26 span {
    margin-left: 3px;
  }
  
  .menu-items26 > a, .menu-items button {
    text-align: left;
    padding: 0.7rem 1rem;
  }
  
  .menu-items26 a:hover,
  .menu-items26 button:hover {
    background-color: #f2f2f2;
    text-decoration: none;
  }
  
  .arrow::after {
    content: "";
    display: inline-block;
    margin-left: 0.28em;
    vertical-align: 0.09em;
    border-top: 0.42em solid;
    border-right: 0.32em solid transparent;
    border-left: 0.32em solid transparent;
  }
  
  .dropdown26 {
    position: absolute;
    right: 0;
    left: auto;
    box-shadow: 0 10px 15px -3px rgba(46, 41, 51, 0.08),
      0 4px 6px -2px rgba(71, 63, 79, 0.16);
    font-size: 0.875rem;
    z-index: 9999;
    min-width: 10rem;
    padding: 0.5rem 0;
    list-style: none;
    background-color: #fff;
    border-radius: 0.5rem;
    display: none;
  }
  
  .dropdown26.show {
    display: block;
  }
  
  .dropdown26 .dropdown26-submenu {
    position: absolute;
    left: 100%;
    top: -7px;
  }