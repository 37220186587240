.filter_all {
  display: flex;
  justify-content: end;
}

.filter_box {
  margin: 0 8px 6px 2px;
}

.modal-content {
  background-color: #fff;
}

.action-icon-edit {
  color: #147fed;
}

.action-icon-delete {
  color: crimson;
}

.error {
  color: crimson;
}

.App {
  padding: 50px;
}

.ag-style {
  height: 280px;
  width: 100%;
}

.vertical-middle {
  display: flex;
  align-items: center;
}

.vertical-middle img {
  display: block;
  border: 1px solid #ddd;
  border-radius: 3px;
}

.ant-pagination-item {
  margin-top: 5px;
}

.err {
  color: red;
  display: none;
}

html {
  --scrollbarBG: #cfd8dc;
  --thumbBG: #90a4ae;
}
body::-webkit-scrollbar {
  width: 11px;
}
body {
  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}
body::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
}
body::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG);
  border-radius: 6px;
  border: 3px solid var(--scrollbarBG);
}

html {
  background: lightgrey;
  overflow: hidden;
}

.ant-checkbox-group-item {
  display: block;
  margin-right: 0;
}

.duplicatesContainer {
  padding: 20px;
}

.duplicatesContainer li {
  margin-bottom: 1rem;
  background: white;
  padding: 10px;
}

/* for button */
.button-arounder {
  font-size: 2rem;
  background: #2b73ff;
  color: hsl(190deg, 10%, 95%);
  margin-bottom: 10px;
  box-shadow: 0 0px 0px hsla(190deg, 15%, 5%, 0.2);
  transfrom: translateY(0);
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;

  --dur: 0.15s;
  --delay: 0.15s;
  --radius: 16px;

  transition: border-top-left-radius var(--dur) var(--delay) ease-out,
    border-top-right-radius var(--dur) calc(var(--delay) * 2) ease-out,
    border-bottom-right-radius var(--dur) calc(var(--delay) * 3) ease-out,
    border-bottom-left-radius var(--dur) calc(var(--delay) * 4) ease-out,
    box-shadow calc(var(--dur) * 4) ease-out,
    transform calc(var(--dur) * 4) ease-out,
    background calc(var(--dur) * 4) steps(4, jump-end);
}

.button-arounder:hover,
.button-arounder:focus {
  box-shadow: 0 4px 8px hsla(190deg, 15%, 5%, 0.2);
  transform: translateY(-4px);
  background: hsl(230deg, 50%, 45%);
  border-top-left-radius: var(--radius);
  border-top-right-radius: var(--radius);
  border-bottom-left-radius: var(--radius);
  border-bottom-right-radius: var(--radius);
}

/* job-fair */

.jobfair-Headings {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 1rem;
  margin-top: 1rem;
}

.jobfair-Headings h2 {
  margin: 0;
  color: #222;
  font-size: 24px;
  font-weight: bold;
}

.jobfair-Headings span {
  display: inline-block;
  color: #999;
  font-size: 14px;
  cursor: pointer;
}

.jobfair-Headings span:hover {
  color: #444;
}

#wrapper {
  position: relative;
}
.company-registration,
.student-registration,
.External-students {
  color: #663399;
  padding: 0.5rem 1rem;
  transition: all 0.3s;
}
.company-registration:hover,
.student-registration:hover,
.External-students:hover {
  color: #552a80 !important;
  background-color: #eee;
}
.company-registration.active,
.student-registration.active,
.External-students.active {
  color: #fff;
  background-color: #663399;
}

/* for job fair*/

.jobfairtable thead th {
  padding: 10px 0; /* Adjust the padding as needed */
}

/* tabs of jobfair */
.company-registration,
.External-students,
.student-registration {
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  border-right: 3px solid #eee;

  color: black;
  font-weight: 600;
}

.company-registration {
  border-left: 3px solid #eee;
}

.student-registration {
  border-left: 3px solid #eee;
}
.student-registration:focus {
  background-color: #663399;
  color: #000 !important;
}

.company-registration:focus {
  background-color: #663399;
  color: #000 !important;
}
.External-students:focus {
  background-color: #663399;
  color: #000 !important;
}

/* .company-registration:hover {
  color: black;
}
.External-students:hover {
  color: black;
} */

.duplicate-table {
  width: 100%;
  border-collapse: collapse;
  background-color: white;
}

.duplicate-table th,
.duplicate-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.duplicate-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.duplicate-table tr:nth-child(even) {
  background-color: #f2f2f2;
}

.duplicate-table tr:hover {
  background-color: #ddd;
}

/* lead page next previous */

.nextprevclass {
  margin-left: 1rem;
}

.edit-button-container {
  padding: 6px 14px 0px 25px;
}

.edit-details-button {
  background-color: #663399;
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 8px;
  transition: background-color 0.3s ease;
  width: 100%; /* Make the button full width */
}

.edit-details-button:hover {
  background-color: #663399;
}

.alumniTableRows {
  text-align: center;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 230%;
}
