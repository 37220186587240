.highlight-primary {
    background-color: rgb(205, 230, 238);
    /* box-shadow: inset 0 2px 0 rgba(0, 0, 0, 0.2), inset 0 -2px 0 rgba(0, 0, 0, 0.2), inset 2px 0 0 rgba(0, 0, 0, 0.2), inset -2px 0 0 rgba(0, 0, 0, 0.2);  } */
  opacity: 1;
}
.highlight-green {
    background-color: rgb(214, 247, 214);
    /* box-shadow: inset 0 2px 0 rgba(0, 0, 0, 0.2), inset 0 -2px 0 rgba(0, 0, 0, 0.2), inset 2px 0 0 rgba(0, 0, 0, 0.2), inset -2px 0 0 rgba(0, 0, 0, 0.2);  } */
  opacity: 0.8;
}
.highlight-red {
    background-color: rgb(249, 195, 195);
    /* box-shadow: inset 0 2px 0 rgba(0, 0, 0, 0.2), inset 0 -2px 0 rgba(0, 0, 0, 0.2), inset 2px 0 0 rgba(0, 0, 0, 0.2), inset -2px 0 0 rgba(0, 0, 0, 0.2);  } */
  opacity: 0.5;
}