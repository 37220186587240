.team-member-1 {
  color: #000;
  font-size: 15px;
  /* font-family: Inter; */
  font-weight: 500;
}

.team-member-2 {
  color: #000;
  font-size: 12px;
  /* font-family: Inter; */
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
}

#handler-container {
  /* max-height: 300px;  */
  overflow-x: auto; /* Enable vertical scrolling */
  overflow-y: hidden;
}
#handler-container::-webkit-scrollbar {
  width: 3px; /* Width of the scrollbar */
  height: 5px;
}
#handler-container::-webkit-scrollbar-track {
  background-color: transparent; /* Color of the scrollbar track */
}
#handler-container::-webkit-scrollbar-thumb {
  background-color: #b4a3e8; /* Color of the scrollbar thumb */
  border-radius: 5px; /* Rounded corners of the scrollbar thumb */
}
#handler-container::-webkit-scrollbar-thumb:hover {
  background-color: #663399; /* Color of the scrollbar thumb on hover */
}
