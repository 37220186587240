.container {
  margin-top: 100px;
  width: 100%;
  padding: 2rem;
  background-color: white;
  height: fit-content;
  border: 1px solid rgba(128, 128, 128, 0.3);
  border-radius: 6px;
}

.container_title {
  font-size: 1.5rem;
  font-weight: 700;
}

.date-picker-container {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  gap: 1rem;
}

.submit-button,
.export-button {
  padding: 0.3rem 1rem;
  font-size: 1rem;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
}

.submit-button {
  background-color: #00173d;
  color: white;
}

.export-button {
  background-color: #82bf6c;
  color: white;
}

.lead-table {
  width: 100%;
  height: max-content;
  border-collapse: collapse;
  margin-top: 20px;
}

.lead-table th,
.lead-table td {
  border: 1px solid #ddd;
  padding: 10px 8px;
}

.lead-table th {
  background-color: #a6aebb;
  font-weight: bold;
}

.lead-table tr:nth-child(even) {
  background-color: #f2f5f7;
}

.error-message {
  color: red;
  margin-top: 10px;
}

button:disabled {
  cursor: not-allowed;
}

.button-text {
  margin-left: 8px;
}

@media only screen and (max-width: 1024px) {
  .container_top {
    flex-direction: column !important;
    align-items: center !important;
    justify-content: center !important;
  }
}
