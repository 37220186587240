.MuiTreeItem-root{
  padding: 10px;

}

.MuiTreeItem-root  .MuiTreeItem-label{
        font-size: 14px;
        font-family:ubuntu-regular,'sans-serif' ;
        padding-block: 2px;
    }
    /* .MuiTreeItem-root  .MuiTreeItem-label:hover {
        background-color: rgb(237, 218, 255) !important;

    } */
.MuiTreeItem-root .Mui-selected{
    background-color: rgb(176, 117, 231) !important;
    color:white;
}
.MuiTreeItem-root .Mui-expanded{
    border-bottom:2px solid rgb(223, 197, 248) !important;

}

/* .Mui-expanded:hover{
    background-color: rgb(176, 117, 231) !important;

} */

/* .activee {
    color: #ffffff; 
    background-color: #999595;
   
  } */
  .active-link {
    background-color: #143163;
    border-radius: 15%;
    /* Add any other styles you want for the active link */
  }